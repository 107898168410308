<!-- Page banner Area -->
<div class="page-banner bg-1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2>Nos Services</h2>
                    <!--<ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Service Details</li>
                    </ul> -->
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page banner Area -->

<!-- Single Service Area -->
<div class="single-services-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="service-details-text">
                    <div class="service-image">
<h3>Votre meilleur choix de transport</h3>
  <p>Quelque soit votre besoin, soyez rassurés que vous trouverez chez nous, le véhicule qu'il vous faut!</p>
<p>Pour vos déplacements court en térrain praticable, nous vous proposons une large gamme de voiture de ville, des plus chics aux  plus pratiques.</p>
<p>Partis pour explorer nos régions, les dunes des niayes, les chutes de tambacounda et autres coins cachés ?  Ne vous inquiétez pas, nous vous proposons une large gamme de 4/4 qui résistent à tout type de terrains.</p>
<p>Pour vos déplacements en petit ou grand comité, nous vous mettons à disposition nos bus et mini bus pouvant contenir jusqu’à une soixantaine de personnes.</p>
<!--<div class="hero-slider owl-carousel owl-theme">
<div class="image">
    <img src="assets/img/services/single-services1.jpg" alt="image">
 </div>
<div class="image">
    <img src="assets/img/services/single-services3.jpg" alt="image">
</div>
</div>-->
<img src="assets/img/services/carrequalite.jpg" alt=""/>
</div>
</div>
<p>Tous nos véhicules sont bien sûr fournis avec le service minimum de climatisation et le plein d'essence.
Notre équipe de chauffeurs professionnels, rigoureux et polyglottes sont disponible pour vous accompagner partout ou vos aventures vous mèneront.
</p>
<h4 style="text-align: center;">CHOISISSEZ DÈS MAINTENANT L'OFFRE QUI VOUS CONVIENT </h4>
<div>

</div>

</div>
<div class="col-lg-4 col-md-12">
<div class="service-sidebar">
            <h3 class="title">Nos Services</h3>
            <ul>
                        <li><a routerLink="/team"><i class='bx bxs-truck' ></i> Véhicules de tourisme</a></li>
                        <li><a routerLink="/team"><i class='bx bxs-ship'></i> Bus</a></li>
                        <li><a routerLink="/team"><i class='bx bxs-ship'></i> 4/4 tout térrain</a></li>
                        <li><a routerLink="/team"><i class='bx bxs-ship' ></i> Mini Bus</a></li>
                        <li><a routerLink="/team"><i class='bx bxs-ship' ></i> Pick up</a></li>
                        <li><a routerLink="/team"><i class='bx bxs-ship' ></i> Berlines </a></li>

                    </ul>

</div>
</div>
        </div>
    </div>
    <div class="hero-slider owl-carousel owl-theme">
<div class="image">
                        <img src="assets/img/services/single-services6.jpg" alt="image">
                    </div>
                    <div class="image">
                        <img src="assets/img/services/single-services7.jpg" alt="image">
                    </div>
 <div class="image">
                        <img src="assets/img/services/single-services8.jpg" alt="image">
                    </div>
</div>
</div>

<!-- End Single Service Area -->

<!-- Newsletter Area -->
<div class="newsletter-area">
    <div class="container">
        <div class="newsletter-content">
            <div class="row align-items-center">
                <div class="col-lg-5 col-md-12">
                    <div class="newsletter-title">
                        <h3>Abonnez vous à notre newsletter:</h3>
                        <p>Découvrez nos nouveaux modèles de véhicules</p>
                    </div>
                </div>

                <div class="col-lg-7 col-md-12">
                    <form class="newsletter-form">
                        <input type="email" class="form-control" placeholder="Entrez votre email" name="EMAIL" required="" autocomplete="off">
                        <button type="submit" class="btn btn-primary">Abonnez-vous</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Newsletter Area -->
