
<!-- Hero Slider -->
<div class="hero-slider owl-carousel owl-theme">
        <div class="hero-slider-item item-bg2">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="banner-content">
                     <!--   <span>Depuis 1992</span>-->
                        <h1>Services de location de voitures</h1>
                        <p>Faîtes nous part de vos envies de location de voitures, nous pouvons certainement les satisfaire.</p>
                        <a routerLink="/contact" class="default-btn-one mr-3">Contactez-nous</a>
                      <!--  <a routerLink="/" class="default-btn-two">Get A Quote</a> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
           <div class="hero-slider-item item-bg1">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="banner-content">
                      <!--  <span>Since 1992</span> -->
                        <h1>Large gamme de véhicules disponibles</h1>
                        <p>Choisissez dès maintenant le véhicule qui vous convient</p>
                        <a routerLink="/contact" class="default-btn-one mr-3">Contactez-nous</a>
                      <!--  <a routerLink="/" class="default-btn-two">Get A Quote</a>-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Hero Slider -->

<!-- Contact Area -->
<div class="container">
    <div class="contact-area mb-85">
        <div class="contact-content">
            <div class="row">
                <div class="col-lg-3 col-sm-6">
                    <div class="contact-card">
                        <i class='bx bxs-phone'></i>
                        <h4>Contact</h4>
                        <p><a href="tel:+221338275726">+221 33 827 57 26</a></p>
                         <p><a href="tel:+221774505629">+221 77 450 56 29</a></p>
                         <p><a href="tel:+221774504008">+221 77 450 40 08</a></p>
                          <p><a href="tel:+221776387161">+221 77 638 71 61</a></p>
                       <!-- <p><a href="tel:+0123456789">+0123 456 789</a></p> -->
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="contact-card">
                        <i class='bx bxs-location-plus'></i>
                        <h4>Notre agence</h4>
                        <p> cite sipres 2 VDN n°102</p>
                        <p>Dakar, Sénégal</p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="contact-card">
                        <i class='bx bx-show'></i>
                        <h4>Heures d'ouverture</h4>
                        <p>9:00 AM - 18:00 PM</p>
                        <p>Du lundi au Samedi </p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="contact-card">
                        <i class='bx bxs-envelope'></i>
                        <h4>Notre Email</h4>
                        <p><a href="mailto:selov@selov.sn">selov@selov.sn</a></p>
                      <!--  <p><a href="mailto:info@gmail.com">info@gmail.com</a></p> -->
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
<!-- End Contact Area -->

<!-- About Area -->
<div class="about-area pb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-12">
                <div class="about-contant">
                    <div class="section-title">
                        <span>SELOV</span>
                        <h2>Le meilleur choix pour vous et vos proches!</h2>
                    </div>
                    <div class="about-text">
                        <p>A votre service depuis 20 ans </p>
                        <p>20 ans que nous vous accompagnons à travers vos différents déplacements partout au Sénégal.</p>
<p>20 années ou nous n'avons céssé de renouveler nos sevrice pour être plus proche de vos attentes.</p>
<p>Encore avec vous pour plus d'aventures!</p>
                        <a routerLink="/about" class="default-btn-one btn-bs">En savoir plus</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-5 col-md-12">
                <div class="about-image">
                    <img src="assets/img/about/about1.jpg" alt="about-image">
                </div>
            </div>

            <div class="col-lg-3 col-md-12">
                <div class="about-tabs">
                    <div class="tab-contant">
                        <h2 class="title">Notre mission</h2>
                      <!--  <nav>
                            <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                <a class="nav-link active" id="nav-vision-tab" data-toggle="tab" href="#nav-vision" role="tab" aria-controls="nav-vision" aria-selected="true">Vision</a>
                                <a class="nav-link nav-link-two" id="nav-mission-tab" data-toggle="tab" href="#nav-mission" role="tab" aria-controls="nav-mission" aria-selected="false">Mission</a>
                                <a class="nav-link nav-link-two" id="nav-value-tab" data-toggle="tab" href="#nav-value" role="tab" aria-controls="nav-value" aria-selected="false">Valeur</a>
                            </div>
                        </nav> -->

                        <div class="tab-content" id="nav-tabContent">
                            <div class="tab-pane fade show active" id="nav-vision" role="tabpanel">
                                <div class="vision">
                    <ul>
                        <li><i class='bx bx-check'></i> Fournir un service de location à l'echelle nationale.</li>
                        <li><i class='bx bx-check'></i> Proposer des véhicules fiables et adaptées aux exigences de nos clients .</li>
                        <li><i class='bx bx-check'></i> Garantir la sécurité de nos clients</li>
                        <li><i class='bx bx-check'></i> Proposer le meilleur rapport qualité/prix du marché.</li>
                    </ul>
                            </div>
                            </div>
<!--
                            <div class="tab-pane fade" id="nav-mission" role="tabpanel">
                                <div class="vision">
                                    <ul>
                                        <li><i class='bx bx-check'></i> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</li>
                                        <li><i class='bx bx-check'></i> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</li>
                                        <li><i class='bx bx-check'></i> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</li>
                                        <li><i class='bx bx-check'></i> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="nav-value" role="tabpanel">
                                <div class="vision">
                                    <ul>
                                        <li><i class='bx bx-check'></i> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</li>
                                        <li><i class='bx bx-check'></i> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</li>
                                        <li><i class='bx bx-check'></i> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</li>
                                        <li><i class='bx bx-check'></i> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</li>
                                    </ul>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End About Area -->

<!-- Services Area
<div class="services-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Our Services</span>
            <h2>Safe and Faster Logistic Services</h2>
        </div>
        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="service-card">
                    <i class='bx bxs-ship'></i>
                    <h3>Ocean Cargo</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                    <a routerLink="/services-details" class="default-btn-two">Read More</a>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="service-card">
                    <i class='bx bx-store'></i>
                    <h3>Cargo Storage</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                    <a routerLink="/services-details" class="default-btn-two">Read More</a>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="service-card">
                    <i class='bx bxs-truck'></i>
                    <h3>Courier Delivery</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                    <a routerLink="/services-details" class="default-btn-two">Read More</a>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="service-card">
                    <i class='bx bx-transfer'></i>
                    <h3>B2B Exchange</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                    <a routerLink="/services-details" class="default-btn-two">Read More</a>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Services Area -->

<!-- Choose Area
<div class="choose-area ptb-100">
    <div class="shape-one"><img src="assets/img/shape/shape5.png" alt="Shape"></div>
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-6">
                <div class="choose-contant">
                    <div class="choose-card">
                        <i class='bx bx-world'></i>
                        <h3>Worldwide Service</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt  magna aliqu.</p>
                    </div>
                </div>
                <div class="choose-contant">
                    <div class="choose-card">
                        <i class='bx bxs-paper-plane'></i>
                        <h3>Tracking Service</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt  magna aliqu.</p>
                    </div>
                </div>
                <div class="choose-contant">
                    <div class="choose-card">
                        <i class='bx bxs-truck'></i>
                        <h3>Fast and Reliable</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt  magna aliqu.</p>
                    </div>
                </div>
                <div class="choose-contant">
                    <div class="choose-card">
                        <i class='bx bx-support'></i>
                        <h3>24/7 Support</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt  magna aliqu.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="choose-text">
                    <div class="section-title">
                        <span>Why Choose Us</span>
                        <h2>Safe and Faster Logistic Service Neer You</h2>
                    </div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris</p>
                    <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.</p>
                    <a routerLink="/contact" class="default-btn-one">Contact Us</a>
                    <div class="shape-image"><img src="assets/img/shape/shape2.png" alt="icon"></div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Choose Area -->

<!-- Speciality Slider Area -->
<div class="services-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Nos services</span>
            <h2>Le meilleur choix pour vous et vos proches</h2>
        </div>

        <div class="speciality-slider owl-carousel owl-theme">
            <div class="speciality-card">
                <a routerLink="/services-details"><img src="assets/img/speciality/speciality11.jpg" alt="image"></a>
                <div class="speciality-text">
                    <h3><a routerLink="/services-details">4/4 tout térrain</a></h3>
                    <p>Affrontez avec sérénité les routes les plus périlleuses à bord de nos 4/4 </p>
                </div>
            </div>

            <div class="speciality-card">
                <a routerLink="/services-details"><img src="assets/img/speciality/speciality22.jpg" alt="image"></a>
                <div class="speciality-text">
                    <h3><a routerLink="/services-details">Bus</a></h3>
                    <p>Voyagez en groupe jusqu'à plus de 60 personnes avec le maximum de confort à bord de nos bus.</p>
                </div>
            </div>

            <div class="speciality-card">
                <a routerLink="/services-details"><img src="assets/img/speciality/speciality33.jpg" alt="image"></a>
                <div class="speciality-text">
                    <h3><a routerLink="/services-details">Mini Bus</a></h3>
                    <p>Optez pour nos mini-bus adaptés pour déplacements en petit comité</p>
                </div>
            </div>



            <div class="speciality-card">
                <a routerLink="/services-details"><img src="assets/img/speciality/speciality44.jpg" alt="image"></a>
                <div class="speciality-text">
                    <h3><a routerLink="/services-details">Vehicules tourisme</a></h3>
                    <p>Notre large gamme de voitures citadines et SUV dernier cris adaptés à vos besoins </p>
                </div>
            </div>
<!--
            <div class="speciality-card">
                <a routerLink="/services-details"><img src="assets/img/speciality/speciality3.jpg" alt="image"></a>
                <div class="speciality-text">
                    <h3><a routerLink="/services-details">Curier Worldwide</a></h3>
                    <p>But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete.</p>
                </div>
            </div> -->
        </div>
    </div>
</div>
<!-- End Speciality Slider Area -->

<!-- Testimonials Area -->
<div class="testimonials-area ptb-100">
    <div class="container">
        <div class="shape-two">
            <img src="assets/img/shape/shape6.png" class="shape-1" alt="shape">
            <img src="assets/img/shape/shape3.png" alt="shape">
        </div>

        <div class="section-title">
            <span>
            <h2>Voyagez avec nous, c'est aussi...</h2></span>
                <h5>Bénéficier des services de notre équipe jeune, dynamique, professionnelle et toujours soucieuse de rendre meilleure votre expérience avec SELOV.</h5>
        </div>

        <div class="testimonials-slider owl-carousel owl-theme">
            <div class="testimonials-card">
            <div class="shape-image">
                    <img src="assets/img/gallery/gallery7.jpg" alt="image">
</div>
            </div>
            <div class="testimonials-card">
 <div class="shape-image">
                    <img src="assets/img/gallery/gallery7.jpg" alt="image">
                </div>
</div>
        </div>
    </div>
</div>
<!-- End Testimonials Area -->

<!-- Partner Area -->
<div class="partner-area pb-100">
    <div class="container">
        <div class="section-title">
            <span>Nos partenaires</span>
            <h2>Ils nous ont fait confiance</h2>
        </div>

        <div class="partner-slider owl-carousel owl-theme">
            <div class="partner-slider-item">
                <a href="#" target="_blank"><img src="assets/img/partner/partner1.png" alt="logo"></a>
            </div>

            <div class="partner-slider-item">
                <a href="#" target="_blank"><img src="assets/img/partner/partner2.png" alt="logo"></a>
            </div>

            <div class="partner-slider-item">
                <a href="#" target="_blank"><img src="assets/img/partner/partner3.png" alt="logo"></a>
            </div>

            <div class="partner-slider-item">
                <a href="#" target="_blank"><img src="assets/img/partner/partner4.png" alt="logo"></a>
            </div>

            <div class="partner-slider-item">
                <a href="#" target="_blank"><img src="assets/img/partner/partner5.png" alt="logo"></a>
            </div>

            <!--<div class="partner-slider-item">
                <a href="#" target="_blank"><img src="assets/img/partner/partner6.png" alt="logo"></a>
            </div>-->
        </div>
    </div>
</div>
<!-- End Partner Area -->


<!-- Transportation Area
<div class="transportation-area">
    <div class="container-fluid p-0">
        <div class="row no-gutters">
            <div class="col-lg-6 col-md-12">
                <div class="transportation-form ptb-100">
                    <div class="section-title">
                        <span>Transportation</span>
                        <h2>Take Your Goods Anywhere</h2>
                    </div>
                    <div class="transportation-shape">
                        <img src="assets/img/shape/shape3.png" alt="icon">
                    </div>
                    <form>
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="Height (CM):">
                        </div>
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="Weight (KG):">
                        </div>
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="Width (CM):">
                        </div>
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="Type of commodity:">
                        </div>
                        <div class="form-group mb-30">
                            <input type="text" class="form-control" placeholder="Distance (KM):">
                        </div>
                        <div class="form-btn">
                            <button type="submit" class="default-btn-one mr-4">Cost Calculate</button>
                            <button type="submit" class="default-btn-two">Get A Full Quote</button>
                        </div>
                        <h3>Get a brochure / 10% Discount on first order</h3>
                    </form>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="popup-video video-bg">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="video-btn">
                                <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="popup-youtube">
                                    <i class="bx bx-play whiteText"></i>
                                    <span class="ripple pinkBg"></span>
                                    <span class="ripple pinkBg"></span>
                                    <span class="ripple pinkBg"></span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Transportation Area -->

<!-- Modal-->
<div class="modal fade" id="myModal" role="dialog" class="modal" >
		<div class="modal-dialog modal-lg">
    <div class="modal-content">
   <div class="modal-body">
      <!--<button id ="myBtn" type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"></span> </button>-->

<span onclick="document.getElementById('myModal').style.display='none'"
        class="w3-button w3-display-topleft">&times;</span>
<div class="hero-slider owl-carousel owl-theme">
<!--<div class="d-table">
            <div class="d-table-cell">
                <div class="container">
<div class="shape-image">   <img src="assets/img/popup/popup1_starlet.jpg"  alt="Third slide"> </div>
                </div>
            </div>
        </div>
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                <div class="shape-image"><img src="assets/img/popup/popup2.jpg" alt="Third slide"></div>
            </div>
        </div>
    </div>
<div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                <div class="shape-image"><img src="assets/img/popup/popup2_kia sportage.jpg" alt="Third slide"></div>
            </div>
        </div>
    </div>-->
 <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
  <div class="shape-image"> <img src="assets/img/popup/popup4.jpg" alt="first slide"> </div>
            </div>
        </div>
    </div>
<div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                <div class="shape-image"><img src="assets/img/popup/popup4_businte.jpg" alt="Third slide"></div>
            </div>
        </div>
    </div>
</div>

</div>

      <div class="modal-footer">
 <h5 class="modal-title" id="myModal" class="default-btn-one mr-3" routerLink="/team" >En savoir plus</h5>

 <!--<button type="button" class="btn btn-primary">En savoir plus</button>
                       <a routerLink="/contact" class="default-btn-one mr-3">Contactez-nous</a>-->

      </div>
</div>
    </div>

<!--end modal-->
