import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-home-one',
  templateUrl: './home-one.component.html',
  styleUrls: ['./home-one.component.scss']
})
export class HomeOneComponent implements OnInit {

  constructor() { }

      ngOnInit(): void {

window.onload = () => {
    var modal = document.getElementById('myModal');
    var btn = document.getElementById("myBtn");
    var span = document.getElementsByClassName("id01")[0];
  /*btn.addEventListener('click', function () {
        modal.style.display = "none";
    });
   span.addEventListener('click', function () {
        modal.style.display = "none";
    });*/
 setTimeout(function(){
        modal.style.display = "block";
   }, 2000);

window.addEventListener('click', function (event) {
        if (event.target == modal) {
            modal.style.display = "none";
        }
    });
}

      }



      }

