<!-- Page banner Area -->
<div class="page-banner bg-3">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2>Notre Politique Qualité</h2>
                   <!-- <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Privacy Policy</li>
                    </ul> -->
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page banner Area -->

<!--Privacy Policy Area-->
<div class="privacy-policy ptb-100">
    <div class="container">
        <div class="privacy-policy-text">

<p>
  <img src="assets/img/services/qualitequalite.jpg" alt=""  align=right/><a> <h2>Notre engagement en matière de qualité</h2>Ces dernières années, la société Selov a augmentée de plus de trente pour cent.Selov a apporté une contribution importante à son développement. Ses valeurs, qui
sont l'expression de la culture d'entreprise de l'entreprise familiale Selov, sont un
facteur de succès économiquement important. Ils sont expérimentés et développés
par la famille Selov depuis la création de l'entreprise au Sénégal et sont les valeurs
fondamentales à Selov</a>
</p>
<br>
<br>
<p>
  <img src="assets/img/services/qualiteefficacite.jpg" alt=""  align=left/><a><h2>Nous visons l'AFRIQUE </h2><p>Selov vous offre tous les services pour des chaînes d'approvisionnement efficaces et
des chaînes à valeur ajoutée. Nous sommes votre partenaire fiable pour les travaux
complexes de logistique et de transport au Sénégal et dans d'autres pays d'Afrique
de l'Ouest</a></p>
<br>
<br>
<br>
<br>

<p>
  <img src="assets/img/services/qualitehumanite.jpg" alt=""  align=right/><a><h2>Notre personnel: une priorité pour nous! </h2>
Nos spécialistes développent pour vous des concepts sur mesure qui
augmentent votre efficacité et garantissent à tout moment la fiabilité des processus.
L'objectif principal de Selov reste de fournir à tout le personnel travaillant chez Selov
un environnement de travail sûr et sans danger dans la mesure du possible,
conformément aux obligations légales HSE actuelles et à d'autres exigences. Nos
agents en travaillant avec nos clients qui sont nos employeurs hôtes, pour s'assurer ​
que tous les employés et parties prenantes comprennent les procédures requises et
travaillent ensemble pour prévenir et contrôler les dangers que les employés peuvent
rencontrer sur leur lieu de travail.</a></p>
<br>
<p>
  <img src="assets/img/services/qualitesecurite.jpg" alt=""  align=left/><a><h2>Faites nous confiance et grandissons ensemble</h2>
VOYAGEZ EN TOUTE SECURITE AVEC SELOV!</a>
</p>
        </div>
    </div>
</div>
<!-- End Privacy Policy Area-->

<!-- Newsletter Area
<div class="newsletter-area">
    <div class="container">
        <div class="newsletter-content">
            <div class="row align-items-center">
                <div class="col-lg-5 col-md-12">
                    <div class="newsletter-title">
                        <h3>Abonnez-vous à notre newsletter:</h3>
                        <p>Découvrez nos derniers modèles</p>
                    </div>
                </div>

                <div class="col-lg-7 col-md-12">
                    <form class="newsletter-form">
                        <input type="email" class="form-control" placeholder="Enter your email" name="EMAIL" required="" autocomplete="off">
                        <button type="submit" class="btn btn-primary">Abonnez-vous</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
 End Newsletter Area -->
