<!-- Page banner Area -->
<div class="page-banner bg-3">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2>Nos Véhicules</h2>
                   <!-- <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Team</li>
                    </ul>-->
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page banner Area -->

<!-- Team Area -->
<div class="team-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Nos véhicules</span>
            <h2>SELOV vous propose  la possibilité de réserver la voiture de votre choix adaptée à votre  budget et vos besoins.</h2>
        </div>

        <div class="row">
<div class="col-lg-4 col-md-6">
                <div class="team-card">
                    <div class="team-image">
                        <img src="assets/img/team/team1.jpg" alt="image">
                        <div class="caption">
                            <ul>
 <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="floza-gallery">
                    <div class="caption">
                        <div class="d-table">
                            <div class="d-table-cell">
                               <li><a href="/assets/img/team/team1-zoomed.jpg"><i class='bx bx-show-alt'></i></a></li>
                               <li><a href="/assets/img/team/team12-zoomed.jpg"></a></li>
                               <li><a href="/assets/img/team/team13-zoomed.jpg"></a></li>
                               <li><a href="/assets/img/team/team14-zoomed.jpg"></a></li>
                               <li><a href="/assets/img/team/team15-zoomed.jpg"></a></li>

                            </div>
                </div>
                </div>
            </div>
        </div>
    </div>
                            </ul>
                        </div>
                    </div>


                    <div class="team-text">
                        <h3>Kia Sportage</h3>
                        <span> 5 places - Essence - Boite automatique </span>
                        <a routerLink="/contact" class="default-btn-one mr-3">Reservez dès maintenant</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="team-card">
                    <div class="team-image">
<div> <br> <br> <br></div>
                        <img src="assets/img/team/team2.jpg" alt="image">
<div> <br> <br> </div>
                        <div class="caption">
                            <ul>
                <div class="floza-gallery">
                    <div class="caption">
                        <div class="d-table">
                            <div class="d-table-cell">
                               <li><a href="/assets/img/team/team2.jpg"><i class='bx bx-show-alt'></i></a></li>
                               <li><a href="/assets/img/team/team21-zoomed.jpg"></a></li>
                               <li><a href="/assets/img/team/team22-zoomed.jpg"></a></li>
                                <li><a href="/assets/img/team/team23-zoomed.jpg"></a></li>
                            </div>
                </div>
                </div>
            </div>
                            </ul>
                        </div>
                    </div>

 <div class="team-text">
                        <h3>Toyota Starlet</h3>
                        <span> 5 places - Essence - Boite automatique </span>
                        <a routerLink="/contact" class="default-btn-one mr-3">Reservez dès maintenant</a>
                    </div>
                </div>
            </div>

  <div class="col-lg-4 col-md-6">
                <div class="team-card">
                    <div class="team-image">
                        <img src="assets/img/team/team3.jpg" alt="image">
                        <div class="caption">
                            <ul>
                <div class="floza-gallery">
                    <div class="caption">
                        <div class="d-table">
                            <div class="d-table-cell">
                               <li><a href="/assets/img/team/team3-zoomed.jpg"><i class='bx bx-show-alt'></i></a></li>
                               <li><a href="/assets/img/team/team31-zoomed.jpg"></a></li>
                               <li><a href="/assets/img/team/team32-zoomed.jpg"></a></li>

                            </div>
                </div>
                </div>
            </div>
                            </ul>
                        </div>
                    </div>

 <div class="team-text">
                        <h3>Peugeot 301 -2021</h3>
                        <span> 5 places - Essence - Boite automatique -Bagages </span>
                        <a routerLink="/contact" class="default-btn-one mr-3">Reservez dès maintenant</a>
                    </div>
                </div>
            </div>

<div class="col-lg-4 col-md-6">
                <div class="team-card">
                    <div class="team-image">
                        <img src="assets/img/team/team4.jpg" alt="image">
                        <div class="caption">
                            <ul>
                <div class="floza-gallery">
                    <div class="caption">
                        <div class="d-table">
                            <div class="d-table-cell">
                               <li><a href="/assets/img/team/team4-zoomed.jpg"><i class='bx bx-show-alt'></i></a></li>
                               <li><a href="/assets/img/team/team41-zoomed.jpg"></a></li>

                            </div>
                </div>
                </div>
            </div>
                            </ul>
                        </div>
                    </div>
                     <div class="team-text">
                        <h3>Toyota Prado TXL </h3>
                        <span> 8 places - Essence -Boite automatique </span>
                        <a routerLink="/contact" class="default-btn-one mr-3">Reservez dès maintenant</a>
                    </div>
                </div>
            </div>

<div class="col-lg-4 col-md-6">
                <div class="team-card">
                    <div class="team-image">
                        <img src="assets/img/team/team5.jpg" alt="image">
                        <div class="caption">
                            <ul>
                <div class="floza-gallery">
                    <div class="caption">
                        <div class="d-table">
                            <div class="d-table-cell">
                               <li><a href="/assets/img/team/team5-zoomed.jpg"><i class='bx bx-show-alt'></i></a></li>
                               <li><a href="/assets/img/team/team51-zoomed.jpg"></a></li>
                               <li><a href="/assets/img/team/team52-zoomed.jpg"></a></li>
                            </div>
                </div>
                </div>
            </div>
                            </ul>
                        </div>
                    </div>
                     <div class="team-text">
                        <h3>Peugeot LANDTREK </h3>
                        <span> 5 places - Essence -Boite automatique </span>
                        <a routerLink="/contact" class="default-btn-one mr-3">Reservez dès maintenant</a>
                    </div>
                </div>
            </div>
<div class="col-lg-4 col-md-6">
                <div class="team-card">
                    <div class="team-image">
<div> <br>  </div>
                        <img src="assets/img/team/team6.jpg" alt="image">
<div> <br> <br> </div>
                        <div class="caption">
                            <ul>
                <div class="floza-gallery">
                    <div class="caption">
                        <div class="d-table">
                            <div class="d-table-cell">
                               <li><a href="/assets/img/team/team6-zoomed.jpg"><i class='bx bx-show-alt'></i></a></li>
                               <li><a href="/assets/img/team/team61-zoomed.jpg"></a></li>
                               <li><a href="/assets/img/team/team62-zoomed.jpg"></a></li>

                            </div>
                </div>
                </div>
            </div>
                            </ul>
                        </div>
                    </div>
                     <div class="team-text">
                        <h3>Toyota COASTER</h3>
                        <span> 5 places - Essence -Boite automatique </span>
                        <a routerLink="/contact" class="default-btn-one mr-3">Reservez dès maintenant</a>
                    </div>
                </div>
            </div>
<div class="col-lg-4 col-md-6">
                <div class="team-card">
<div> <br> <br></div>
                    <div class="team-image">
                        <img src="assets/img/team/team11.jpg" alt="image">
<div> <br> <br> <br></div>
                        <div class="caption">
                            <ul>
                <div class="floza-gallery">
                    <div class="caption">
                        <div class="d-table">
                            <div class="d-table-cell">
                               <li><a href="/assets/img/team/team111-zoomed.jpg"><i class='bx bx-show-alt'></i></a></li>
                               <li><a href="/assets/img/team/team112-zoomed.jpg"></a></li>
                               <li><a href="/assets/img/team/team113-zoomed.jpg"></a></li>
                               <li><a href="/assets/img/team/team114-zoomed.jpg"></a></li>

                            </div>
                </div>
                </div>
            </div>
                            </ul>
                        </div>
                    </div>
                     <div class="team-text">
                        <h3>Bus 30 places</h3>
                        <span>  </span>
                        <a routerLink="/contact" class="default-btn-one mr-3">Reservez dès maintenant</a>
                    </div>
                </div>
            </div>






            <div class="col-lg-4 col-md-6">
                <div class="team-card">
<div> <br> <br></div>
                    <div class="team-image">
                        <img src="assets/img/team/team7.jpg" alt="image">
<div> <br> <br> <br></div>
                        <div class="caption">
                            <ul>
                <div class="floza-gallery">
                    <div class="caption">
                        <div class="d-table">
                            <div class="d-table-cell">
                               <li><a href="/assets/img/team/team71-zoomed.jpg"><i class='bx bx-show-alt'></i></a></li>
                               <li><a href="/assets/img/team/team72-zoomed.jpg"></a></li>

                            </div>
                </div>
                </div>
            </div>
                            </ul>
                        </div>
                    </div>
                     <div class="team-text">
                        <h3>Bus 35 places</h3>
                        <span>  </span>
                        <a routerLink="/contact" class="default-btn-one mr-3">Reservez dès maintenant</a>
                    </div>
                </div>
            </div>

<div class="col-lg-4 col-md-6">
                <div class="team-card">
                    <div class="team-image">
                        <img src="assets/img/team/team8.jpg" alt="image">
                        <div class="caption">
                            <ul>
                <div class="floza-gallery">
                    <div class="caption">
                        <div class="d-table">
                            <div class="d-table-cell">
                               <li><a href="/assets/img/team/team81-zoomed.jpg"><i class='bx bx-show-alt'></i></a></li>
                               <li><a href="/assets/img/team/team82-zoomed.jpg"></a></li>
                               <li><a href="/assets/img/team/team83-zoomed.jpg"></a></li>
                            </div>
                </div>
                </div>
            </div>
                            </ul>
                        </div>
                    </div>

                     <div class="team-text">
                        <h3>Bus 44 places </h3>
                        <span> </span>
                        <a routerLink="/contact" class="default-btn-one mr-3">Reservez dès maintenant</a>
                    </div>
                </div>
            </div>

<div class="col-lg-4 col-md-6">
                <div class="team-card">
                    <div class="team-image">
                        <img src="assets/img/team/team8.jpg" alt="image">
                        <div class="caption">
                            <ul>
                <div class="floza-gallery">
                    <div class="caption">
                        <div class="d-table">
                            <div class="d-table-cell">
                               <li><a href="/assets/img/team/team91-zoomed.jpg"><i class='bx bx-show-alt'></i></a></li>
                               <li><a href="/assets/img/team/team92-zoomed.jpg"></a></li>
                               <li><a href="/assets/img/team/team93-zoomed.jpg"></a></li>


                            </div>
                </div>
                </div>
            </div>
                            </ul>
                        </div>
                    </div>

                     <div class="team-text">
                        <h3>Bus 50 places </h3>
                        <span> </span>
                        <a routerLink="/contact" class="default-btn-one mr-3">Reservez dès maintenant</a>
                    </div>
                </div>
            </div>

<div class="col-lg-4 col-md-6">
                <div class="team-card">
                    <div class="team-image">
<div> <br> <br></div>
                        <img src="assets/img/team/team10.jpg" alt="image">
<div> <br> <br> <br> <br> </div>
                        <div class="caption">
                            <ul>
                <div class="floza-gallery">
                    <div class="caption">
                        <div class="d-table">
                            <div class="d-table-cell">
                               <li><a href="/assets/img/team/team101-zoomed.jpg"><i class='bx bx-show-alt'></i></a></li>
                               <li><a href="/assets/img/team/team102-zoomed.jpg"></a></li>

                            </div>
                </div>
                </div>
            </div>
                            </ul>
                        </div>
                    </div>
                     <div class="team-text">
                        <h3>Bus 55 places </h3>
                        <span> </span>
                        <a routerLink="/contact" class="default-btn-one mr-3">Reservez dès maintenant</a>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<!-- End Team Area -->

<!-- Newsletter Area -->
<div class="newsletter-area">
    <div class="container">
        <div class="newsletter-content">
            <div class="row align-items-center">
                <div class="col-lg-5 col-md-12">
                    <div class="newsletter-title">
                        <h3>Subscribe to our newsletter:</h3>
                        <p>Focused on the transport and logistic industry</p>
                    </div>
                </div>

                <div class="col-lg-7 col-md-12">
                    <form class="newsletter-form">
                        <input type="email" class="form-control" placeholder="Enter your email" name="EMAIL" required="" autocomplete="off">
                        <button type="submit" class="btn btn-primary">Subscribe</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Newsletter Area -->
