<!-- Coming Soon Area -->
<div class="coming-soon-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="coming-soon-contant">
                    <h1>Coming Soon</h1>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices.</p>
                    <div id="timer">
                        <div id="days">{{days}} <span>Days</span></div>
                        <div id="hours">{{hours}} <span>Hours</span></div>
                        <div id="minutes">{{minutes}} <span>Minutes</span></div>
                        <div id="seconds">{{seconds}} <span>Seconds</span></div>
                    </div>
                    <div class="coming-soon-form">
                        <form>
                            <div class="form-group">
                                <input type="email" class="form-control" id="email" placeholder="Inter Your Email">
                            </div>
                            <button type="submit" class="form-btn">NOTIFY ME</button>
                        </form>
                    </div>
                    <ul>
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter' ></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt' ></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Coming Soon Area -->