<!-- Page banner Area -->
<div class="page-banner bg-3">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2>Actualités</h2>
                    <!-- <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Blog</li>
                    </ul> -->
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page banner Area -->

<div class="blog-area-two ptb-100">
    <div class="container">
      <!--  <div class="section-title">
           <span>Our Blog</span>
            <h2> </h2>
        </div> -->

        <div class="row">


            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <a class="blog-image" routerLink="/blog-details"><img src="assets/img/blog/blog2.jpg" alt="image"></a>
                    <div class="blog-text">
                        <span class="meta-tag">Sénégal/Covid-19</span>
                        <div class="date">25 Janv 2021</div>
                            <h3><a> Instauration du couvre-feu au Sénégal</a></h3>
                        <p class="mb-20">Notez qu'un couvre-feu est actuellement instauré dans les régions  Dakar et Thiès</p>
                     <!--  <a routerLink="/blog-details" class="default-btn-two">Read More</a> -->
                    </div>
                </div>
            </div>
             <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <a class="blog-image" routerLink="/blog-details"><img src="assets/img/blog/blog11.jpg" alt="image"></a>
                    <div class="blog-text">
                        <span class="meta-tag">Transport/permis</span>
                        <div class="date">12 Janv 2020</div>
                       <h3><a>Acquisition de nouveaux permis</a></h3>
                        <p class="mb-20">Nous informons nos clients que l'ancien permis n'est plus légal sur toute l’étendue du territoire sénégalais et sa présentation sera considérée comme une infraction routière.
                        Les demandes de nouveaux permis numérisés s'effectuent dans les sites CAPP Karangë  - https://cappkarange.sn.        </p>           <!-- <a routerLink="/blog-details" class="default-btn-two">Read More</a> -->
                    </div>
                </div>
            </div>
             <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <a class="blog-image" routerLink="/blog-details"><img src="assets/img/blog/blog1.jpg" alt="image"></a>
                    <div class="blog-text">
                        <span class="meta-tag">Sénégal/Covid-19</span>
                        <div class="date">01 Nov 2020</div>
                        <h3><a routerLink="/blog-details">Respect des gestes barrières</a></h3>
                        <p class="mb-20">Selov se mobilise pour endiguer la propagation de la covid-19 au Sénégal. Nous continuons à renforcer notre protocole sanitaires et exhortons nos clients à respecter le port du masque et les gestes barrières. </p>
                      <!--  <a routerLink="/blog-details" class="default-btn-two">Read More</a> -->
                    </div>
                </div>
            </div>


            <!--
            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <a class="blog-image" routerLink="/blog-details"><img src="assets/img/blog/blog12.jpg" alt="image"></a>
                    <div class="blog-text">
                        <span class="meta-tag">Transport</span>
                        <div class="date">25 August, 2020</div>
                        <h3><a routerLink="/blog-details">Goods will be contain in certified safe</a></h3>
                        <p class="mb-20">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do tempor</p>
                        <a routerLink="/blog-details" class="default-btn-two">Read More</a>
                    </div>
                </div>
            </div>
-->
<!--
            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <a class="blog-image" routerLink="/blog-details"><img src="assets/img/blog/blog13.jpg" alt="image"></a>
                    <div class="blog-text">
                        <span class="meta-tag">Delivery</span>
                        <div class="date">26 August, 2020</div>
                        <h3><a routerLink="/blog-details">Introduce new boat service in this spring</a></h3>
                        <p class="mb-20">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do tempor</p>
                        <a routerLink="/blog-details" class="default-btn-two">Read More</a>
                    </div>
                </div>
            </div>
-->
<!--
            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <a class="blog-image" routerLink="/blog-details"><img src="assets/img/blog/blog10.jpg" alt="image"></a>
                    <div class="blog-text">
                        <span class="meta-tag">Transport</span>
                        <div class="date">27 August, 2020</div>
                        <h3><a routerLink="/blog-details">Our customer around the world satisty with it</a></h3>
                        <p class="mb-20">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do tempor</p>
                        <a routerLink="/blog-details" class="default-btn-two">Read More</a>
                    </div>
                </div>
            </div>
    -->
            <!-- Pagination -->
            <div class="col-lg-12 col-md-12">
                <ul class="pagination">
                    <li class="page-item"><a class="page-link" routerLink="/blog"><span aria-hidden="true">&laquo;</span></a></li>
                    <li class="page-item active"><a class="page-link" routerLink="/blog">1</a></li>
                    <li class="page-item"><a class="page-link" routerLink="/blog">2</a></li>
                    <li class="page-item"><a class="page-link" routerLink="/blog">3</a></li>
                    <li class="page-item"><a class="page-link" routerLink="/blog"><span aria-hidden="true">&raquo;</span></a></li>
                </ul>
            </div>
        </div>
    </div>
</div>
<!-- End Blog Area -->
