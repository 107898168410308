<!-- Page banner Area -->
<div class="page-banner bg-3">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2>Sign Up</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Sign Up</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page banner Area -->

<!-- Start Sign up Area -->
<div class="sign-up-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Create an Account!</h2>
            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Laudantium quas cumque iste veniam id dolorem deserunt ratione error voluptas rem ullam.</p>
        </div>

        <div class="sign-up-form">
            <form>
                <div class="row">
                    <div class="col-lg-6 col-md-6">
                        <div class="form-group">
                            <input type="text" class="form-control" id="first_name" placeholder="First name">
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="form-group">
                            <input type="text" class="form-control" id="last_name" placeholder="Last name">
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <input type="text" class="form-control" id="username" placeholder="Enter your Username">
                </div>

                <div class="form-group">
                    <input type="email" class="form-control" id="email" placeholder="Email Address">
                </div>

                <div class="form-group">
                    <input type="password" class="form-control" id="password" placeholder="Password">
                </div>

                <div class="form-group">
                    <input type="password" class="form-control" id="password" placeholder="Confirm Password">
                </div>

                <div class="form-group form-check text-center">
                    <input type="checkbox" class="form-check-input" id="exampleCheck1">
                    <label class="form-check-label" for="exampleCheck1">I agree with Terms & Conditions</label>
                </div>
                
                <div class="text-center">
                    <button type="submit" class="btn default-btn-one">Sign Up</button>
                    <p class="account-decs">Already have an account? <a routerLink="/sign-in">Sign In</a></p>
                </div>
            </form>
        </div>
    </div>
</div>
<!-- End Sign up  Area -->

<!-- Newsletter Area -->
<div class="newsletter-area">
    <div class="container">
        <div class="newsletter-content">
            <div class="row align-items-center">
                <div class="col-lg-5 col-md-12">
                    <div class="newsletter-title">
                        <h3>Subscribe to our newsletter:</h3>
                        <p>Focused on the transport and logistic industry</p>
                    </div>
                </div>

                <div class="col-lg-7 col-md-12">
                    <form class="newsletter-form">
                        <input type="email" class="form-control" placeholder="Enter your email" name="EMAIL" required="" autocomplete="off">
                        <button type="submit" class="btn btn-primary">Subscribe</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Newsletter Area -->