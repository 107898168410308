<!-- Page banner Area -->
<div class="page-banner bg-3">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2>Sign In</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Sign In</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page banner Area -->

<!-- Start Sign in Area -->
<div class="sign-in-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Sign in to Your Account!</h2>
            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Laudantium quas cumque iste veniam id dolorem deserunt ratione error voluptas rem ullam.</p>
        </div>

        <div class="sign-in-form">
            <form>
                <div class="form-group">
                    <input type="email" class="form-control" id="email" placeholder="Username or Email">
                </div>

                <div class="form-group">
                    <input type="password" class="form-control" id="password" placeholder="Password">
                </div>

                <div class="form-group form-check text-center">
                    <input type="checkbox" class="form-check-input" id="exampleCheck1">
                    <label class="form-check-label" for="exampleCheck1">Remember me</label>
                </div>
            
                <div class="text-center">
                    <button type="submit" class="btn default-btn-one">Sign In</button>
                    <p class="account-decs">Not a member? <a routerLink="/sign-up">Sign Up</a></p>
                </div>
            </form>
        </div>
    </div>
</div>
<!-- End Sign in  Area -->

<!-- Newsletter Area -->
<div class="newsletter-area">
    <div class="container">
        <div class="newsletter-content">
            <div class="row align-items-center">
                <div class="col-lg-5 col-md-12">
                    <div class="newsletter-title">
                        <h3>Subscribe to our newsletter:</h3>
                        <p>Focused on the transport and logistic industry</p>
                    </div>
                </div>

                <div class="col-lg-7 col-md-12">
                    <form class="newsletter-form">
                        <input type="email" class="form-control" placeholder="Enter your email" name="EMAIL" required="" autocomplete="off">
                        <button type="submit" class="btn btn-primary">Subscribe</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Newsletter Area -->