import { Component, OnInit } from '@angular/core';
//import * as $ from 'jquery';
@Component({
  selector: 'app-home-two',
  templateUrl: './home-two.component.html',
  styleUrls: ['./home-two.component.scss']
})
export class HomeTwoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
/*
$(document).ready(function(){
 var $: any;
  $('.your-class').slick();
});

$('.modal').on('shown.bs.modal', function (e) {
 // $('.your-class').slick('setPosition');
  //$('.wrap-modal-slider').addClass('open');
})*/

  }

}
