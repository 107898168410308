<!-- Footer Area-->
<footer class="footer-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="footer-widget">
  <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15434.109329274885!2d-17.4723816!3d14.7392948!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x9fe792486848fe0d!2sSELOV!5e0!3m2!1sfr!2ssn!4v1612792534826!5m2!1sfr!2ssn" width="600" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                   <!-- <div class="logo">
                        <img src="assets/img/logo.png" alt="logo">
                    </div>
                    <p>La Selov, Senegalaise de Location de Voitures, le meilleur choix pour vous et vos proches.</p>
                    <ul class="footer-socials">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter' ></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                    </ul>-->
                </div>
            </div>

           <!-- <div class="col-lg-3 col-sm-6">
                <div class="footer-widget pl-80">
                    <h3>Company</h3>

                    <ul class="footer-text">
                        <li><a routerLink="/about">About Us</a></li>
                        <li><a routerLink="/services">Services</a></li>
                        <li><a routerLink="/">Features</a></li>
                        <li><a routerLink="/">Cost calculator</a></li>
                        <li><a routerLink="/">Latest News</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="footer-widget pl-50">
                    <h3>Support</h3>

                    <ul class="footer-text">
                        <li><a routerLink="/faq">FAQ’s</a></li>
                        <li><a routerLink="/privacy-policy">Privacy Policy</a></li>
                        <li><a routerLink="/terms-conditions">Terms & Conditions</a></li>
                        <li><a routerLink="/">Community</a></li>
                        <li><a routerLink="/contact">Contact Us</a></li>
                    </ul>
                </div>
            </div>
        -->
   <div class="col-lg-3 col-sm-6">
   </div>
  <div class="col-lg-3 col-sm-6">
  </div>

            <div class="col-lg-3 col-sm-6">
                <div class="footer-widget">
                    <h3>Contact Info</h3>

                    <ul class="info-list">
                        <li><i class='bx bxs-location-plus'></i> Cite Sipres 2 Villa n°102 VDN</li>
                        <li><i class='bx bxs-envelope'></i> <a href="mailto:selov@selov.sn">selov@selov.sn</a></li>
                        <li><i class='bx bxs-phone'></i> <a href="tel:+221338275729">33 827 57 29</a></li>
                        <li><i class='bx bxs-phone'></i> <a href="tel:+221774505629">+221 77 450 56 29</a></li>
                        <li><i class='bx bxs-phone'></i> <a href="tel:+221774504008">+221 77 450 40 08</a></li>
                        <li><i class='bx bxs-phone'></i> <a href="tel:+221776387161">+221 77 638 71 61</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</footer>
<!-- End Footer Area -->

<!-- Footer bottom Area -->
<div class="footer-bottom">
    <div class="container">
        <p>Copyright Yaqin. Tous droits reservés <a href="https://yaqin-tech.com/" target="_blank"></a></p>
    </div>
</div>
<!-- End Footer bottom Area -->

<!-- Go Top -->
<div class="go-top">
    <i class='bx bx-chevrons-up'></i>
</div>
<!-- End Go Top -->
