<!-- Page banner Area -->
<div class="page-banner bg-1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2>Qui-sommes-nous?</h2>
                  <!-- <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>About Us</li>
                    </ul> -->
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page banner Area -->

<!-- About Safe Area -->
<div class="about-text-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-safe-text">
                    <h2>Service de location de voiture sûr, rapide et efficace</h2>
                    <p> Avec un parc automobile très diversifié (vehicule de tourisme, vehicule utilitaire, vehicule 4x4 tous terrains et Mini Bus) la Selov (Senegalaise de Location de Voiture) qui capitalise déjà de longues années d'experience, propose a sa clientèle une large gamme de véhicules modernes, neufs, climatisés, alliant le luxe et le confort à des prix trés abordables et très competitifs..</p>
                </div>
<!--
                <div class="shipping-card">
                    <div class="shipping-contant">
                        <div class="shipping-sign">
                            <img src="assets/img/sign.png" alt="image">
                        </div>

                        <div class="shipping-image">
                            <img src="assets/img/clients/client1.png" alt="image">
                        </div>

                        <h3>John Doe</h3>
                        <span>CEO, Floza</span>
                        <p>Nor again is there anyone who loves or pursues or desires to.</p>
                    </div>
                </div> -->
            </div>

            <div class="col-lg-6 col-md-12">
            <div class="about-info-card">
                    <h3>Notre Mission</h3>
                    <ul>
                        <li><i class='bx bx-check'></i> Fournir un service de location à l'echelle nationale.</li>
                        <li><i class='bx bx-check'></i> Proposer des véhicules fiables et adaptées aux exigences de nos clients .</li>
                        <li><i class='bx bx-check'></i> Garantir la sécurité de nos clients</li>
                        <li><i class='bx bx-check'></i> Proposer le meilleur rapport qualité/prix du marché.</li>
                    </ul>
                </div>
            <!-- <div class="safe-image">
                    <img src="assets/img/speciality/speciality2.jpg" alt="image">
                </div>
            -->
            </div>
        </div>
    </div>
</div>
<!-- End About Safe Area -->

<!-- About Info
<div class="about-info-area pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="about-info-card">
                    <h3>Notre Mission</h3>

                    <ul>
                        <li><i class='bx bx-check'></i> Fournir un service de location à l'echelle nationale.</li>
                        <li><i class='bx bx-check'></i> Proposer des véhicules fiables et adaptées aux exigences de nos clients .</li>
                        <li><i class='bx bx-check'></i> Garantir la sécurité de nos clients</li>
                        <li><i class='bx bx-check'></i> Proposer le meilleur rapport qualité/prix du marché.</li>
                    </ul>
                </div>
            </div>


            <div class="col-lg-4 col-md-6">
                <div class="about-info-card">
                    <h3>Our Promises</h3>

                    <ul>
                        <li><i class='bx bx-check'></i> We provide  best logistic service worldwide.</li>
                        <li><i class='bx bx-check'></i> All payment methods we accept.</li>
                        <li><i class='bx bx-check'></i> We provide proper safety and security.</li>
                        <li><i class='bx bx-check'></i> We provide  best logistic service worldwide.</li>
                        <li><i class='bx bx-check'></i> All payment methods we accept.</li>
                        <li><i class='bx bx-check'></i> We provide proper safety and security.</li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="about-info-card">
                    <h3>Our Vission</h3>

                    <ul>
                        <li><i class='bx bx-check'></i> We provide  best logistic service worldwide.</li>
                        <li><i class='bx bx-check'></i> All payment methods we accept.</li>
                        <li><i class='bx bx-check'></i> We provide proper safety and security.</li>
                        <li><i class='bx bx-check'></i> We provide  best logistic service worldwide.</li>
                        <li><i class='bx bx-check'></i> All payment methods we accept.</li>
                        <li><i class='bx bx-check'></i> We provide proper safety and security.</li>
                    </ul>
                </div>
            </div>

        </div>
    </div>
</div>
<!-- End About Info -->

<!-- Digital Area -->
<div class="digital-area ptb-100">
    <div class="container">
        <div class="digital-top-contant">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="digital-image">
                        <img src="assets/img/blog/blog7.jpg" alt="image">
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="digital-text">
                        <h2>A votre service depuis <span>20 ans</span></h2>
                        <p>20 ans que nous vous accompagnons à travers vos différents déplacements partout au Sénégal.
<p>20 années ou nous n'avons céssé de renouveler nos sevrice pour être plus proche de vos attentes.
</p>
<p>Encore avec vous pour plus d'aventures!</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="digital-card-contant">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="digital-card">
                        <div class="card-text">
                            <i class='bx bx-cart-alt'></i>
                            <h3><span>2001</span> - Creation de SELOV</h3>
                           <!-- <p>orem ipsum dolor sit amet elit, sed do eiusmod tempor incididunt ut labore et .</p>-->
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="digital-card">
                        <div class="card-text">
                            <i class='bx bx-map-alt'></i>
                            <h3><span>2001</span> - Couverture régionale </h3>
                           <!-- <p>orem ipsum dolor sit amet elit, sed do eiusmod tempor incididunt ut labore et .</p> -->
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="digital-card">
                        <div class="card-text">
                            <i class='bx bxs-truck' ></i>
                            <h3><span>2002</span> - Couverture nationale</h3>
                          <!--  <p>orem ipsum dolor sit amet elit, sed do eiusmod tempor incididunt ut labore et .</p> -->
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="digital-card">
                        <div class="card-text">
                            <i class='bx bx-award'></i>
                            <h3><span>2014</span> -Extension flotte-Services berlines et bus </h3>
                          <!--  <p>orem ipsum dolor sit amet elit, sed do eiusmod tempor incididunt ut labore et .</p> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Digital Area -->

<!-- partner Slider Area -->
<div class="partner-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Partenaires</span>
            <h2>Ils nous ont fait confiance</h2>
        </div>

        <div class="partner-slider owl-carousel owl-theme">
            <div class="partner-slider-item">
                <a href="#" target="_blank"><img src="assets/img/partner/partner1.png" alt="logo"></a>
            </div>

            <div class="partner-slider-item">
                <a href="#" target="_blank"><img src="assets/img/partner/partner2.png" alt="logo"></a>
            </div>

            <div class="partner-slider-item">
                <a href="#" target="_blank"><img src="assets/img/partner/partner3.png" alt="logo"></a>
            </div>

            <div class="partner-slider-item">
                <a href="#" target="_blank"><img src="assets/img/partner/partner4.png" alt="logo"></a>
            </div>

            <div class="partner-slider-item">
                <a href="#" target="_blank"><img src="assets/img/partner/partner5.png" alt="logo"></a>
            </div>

            <div class="partner-slider-item">
                <a href="#" target="_blank"><img src="assets/img/partner/partner6.png" alt="logo"></a>
            </div>
        </div>
    </div>
</div>
<!-- End partner Slider Area -->

<!-- Newsletter Area
<div class="newsletter-area">
    <div class="container">
        <div class="newsletter-content">
            <div class="row align-items-center">
                <div class="col-lg-5 col-md-12">
                    <div class="newsletter-title">
                        <h3>Subscribe to our newsletter:</h3>
                        <p>Focused on the transport and logistic industry</p>
                    </div>
                </div>

                <div class="col-lg-7 col-md-12">
                    <div class="newsletter-form">
                        <form>
                            <input type="email" class="form-control" placeholder="Email Address" required autocomplete="off">
                            <button type="submit" class="btn btn-primary">Subscribe</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Newsletter Area -->
